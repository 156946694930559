var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-card",
    {
      staticStyle: { "margin-bottom": "5px", width: "100%" },
      attrs: { shadow: "never" },
    },
    [
      _c("div", { staticClass: "top" }, [
        _c("label", { staticClass: "title" }, [_vm._v(_vm._s(_vm.content))]),
        _c("div", { staticClass: "right" }, [
          _c("span", { staticClass: "start" }, [
            _vm._v(" " + _vm._s(_vm.start_time)),
          ]),
          _c("span", { staticClass: "end" }, [
            _vm._v(_vm._s(_vm.end_time) + " "),
          ]),
        ]),
      ]),
      !_vm.hide_attendees
        ? _c(
            "div",
            { staticClass: "attendees" },
            _vm._l(_vm.sorted_attendees, function (attendee) {
              return _c("calendar-event-attendee", {
                key: attendee.email,
                attrs: { attendee: attendee },
              })
            }),
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "section-footer" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("dateformat")(_vm.event.start.toDate(), "ddd Do MMM")
                ) +
                ", "
            ),
          ]),
          _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "bottom-end" },
              on: { command: _vm.runCommand },
            },
            [
              _c(
                "div",
                {
                  directives: [{ name: "only-admin", rawName: "v-only-admin" }],
                  staticClass: "down-icon",
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "icon",
                      attrs: { focusable: "false", viewBox: "0 0 24 24" },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M3.5,8.9c0-0.4,0.1-0.7,0.4-1C4.5,7.3,5.4,7.2,6,7.8l5.8,5.2l6.1-5.2C18.5,7.3,19.5,7.3,20,8c0.6,0.6,0.5,1.5-0.1,2.1 l-7.1,6.1c-0.6,0.5-1.4,0.5-2,0L4,10.1C3.6,9.8,3.5,9.4,3.5,8.9z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("el-dropdown-item", { attrs: { command: "ignore" } }, [
                    _vm._v(" Ignore event "),
                  ]),
                  _vm.event.recurringEventId
                    ? _c(
                        "el-dropdown-item",
                        { attrs: { command: "ignoreRecurring" } },
                        [_vm._v(" Ignore recurring ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }