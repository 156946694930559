var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attendee" },
    [
      _c("avatar", {
        attrs: {
          user: _vm.user,
          show_initials: _vm.user.show_initials,
          hide_tooltip: !_vm.user.show_initials,
          color: _vm.color,
          live: true,
          custom_initials: _vm.user.custom_initials,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }